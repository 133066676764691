.is-invalid {
  border: 1px solid rgb(212, 19, 13) !important;
  border-radius: 4px !important;
}
.error-message {
  color: rgb(220, 38, 38);
  font-size: 0.8rem;
}
.register-technician-form .is-invalid {
  border-color: #f86c6b;
}
.technician-modal .sk-circle {
  margin: 0 auto;
}
.sm-orange-button {
  background-color: var(--sm-orange) !important;
  border-color: var(--sm-orange) !important;
}
.Toastify__toast-body {
  word-break: break-word !important;
}

// Here you can add other styles

// Common Start
body {
  color: #5c4f51;
}

.font-color-red {
  color: red;
}

.font-color-gray {
  color: gray;
}

.font-small {
  font-size: 10px;
}

.font-size-10 {
  font-size: 10px;
}

// Common End

.quotes-detail-card .row {
  margin-left: 0px;
  padding-bottom: 5px;
  padding-top: 10px;
}

.sm-logo-login-page {
  width: 200px;
  margin-bottom: 10px;
}

.search-button {
  float: right;
  margin-left: 10px;
}

.search-field-container {
  overflow: hidden;
}

.search-field {
  width: 100%;
}

.react-bs-table-bordered {
  .react-bs-container-header {
    font-size: 13px; /* table header font size*/
  }
  .react-bs-container-body {
    font-size: 12.5px; /* table rows font size*/
  }
  /*table td, table th {
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
    }*/

  table th {
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  /* Retina-specific stuff here */
  .react-bs-table-bordered {
    .react-bs-container-header {
      font-size: 14px; /* table header font size*/
    }
    .react-bs-container-body {
      font-size: 13px; /* table rows font size*/
    }
  }
}

/* Removes 'clear' button from input type time. */
input[type="time"].clear-time::-webkit-clear-button {
  display: none;
}

/* Removes 'clear' button from input type date. */
input[type="date"].clear-date::-webkit-clear-button {
  display: none;
}

.pointer-cursor {
  cursor: pointer;
}

.linebreak-text {
  white-space: pre-wrap;
}

// Clearner Tab
.cleanerDetail-row {
  padding: 10px;
}

.cleanerDetail-button {
  margin: 10px;
}

// Booking tab
.payment-icon-details {
  width: 48px;
}

// Promotion Tab
.leadDetail-content div div:first-child {
  padding: 10px 10px 10px 20px;
}
.leadDetail-content div div:nth-child(2) {
  padding: 10px 10px 10px 20px;
}

// Booking Change Request START

.text-muted {
  color: #73818f !important;
}

.capitalize-text {
  text-transform: capitalize;
}

.right-margin-small {
  margin-right: 10px;
}

.right-margin-v-small {
  margin-right: 5px;
}

.left-margin-v-small {
  margin-left: 5px;
}

.google-map-arrow-separator {
  justify-content: center;
  align-items: center;
  display: flex;
}

.right-float {
  float: right;
}

.btn-brand-orange {
  color: #fff;
  background-color: var(--sm-orange);
  border-color: var(--sm-orange);
}

.btn-brand-orange:hover {
  color: #fff;
  background-color: #e24713;
  border-color: #e24713;
}

.btn-brand-orange:focus,
.btn-brand-orange.focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 122, 21, 0.5);
}

.btn-brand-orange.disabled,
.btn-brand-orange:disabled {
  color: #fff;
  background-color: #fe7a15;
  border-color: #fe7a15;
}

.btn-brand-orange:not(:disabled):not(.disabled):active,
.btn-brand-orange:not(:disabled):not(.disabled).active,
.show > .btn-brand-orange.dropdown-toggleTabClick {
  color: #fff;
  background-color: #df6101;
  border-color: #df6101;
}

.btn-brand-orange:not(:disabled):not(.disabled):active:focus,
.btn-brand-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-brand-orange.dropdown-toggleTabClick:focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 122, 21, 0.5);
}

.btn-brand-blue {
  color: #fff;
  background-color: var(--sm-blue);
  border-color: var(--sm-blue);
}

.btn-brand-blue:hover {
  color: #fff;
  background-color: #007ba3;
  border-color: #007ba3;
}

.btn-brand-blue:focus,
.btn-brand-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 163, 0.5);
}

.btn-brand-blue.disabled,
.btn-brand-blue:disabled {
  color: #fff;
  background-color: #00a9e1;
  border-color: #00a9e1;
}

.btn-brand-blue:not(:disabled):not(.disabled):active,
.btn-brand-blue:not(:disabled):not(.disabled).active,
.show > .btn-brand-blue.dropdown-toggleTabClick {
  color: #fff;
  background-color: #007ba3;
  border-color: #007ba3;
}

.btn-brand-blue:not(:disabled):not(.disabled):active:focus,
.btn-brand-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-brand-blue.dropdown-toggleTabClick:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 163, 0.5);
}

// Booking Change Request END

// Profile
.profile-card .card-title {
  margin-bottom: 0;
}

.address-details .row {
  padding: 5px 5px 5px 10px;
}

.contact-details hr {
  margin: 0;
  width: 150px;
}

.profile-image {
  margin-top: 20px;
  border-radius: "20px";
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: "50%";
}

// Reports

.myBilling-card .react-bs-table-container {
  padding: 0px 22px 22px 22px;
}

.myBilling-card .available-balance {
  padding: 10px 5px 5px 20px;
  font-size: 18px;
}

.myBilling-card .head-float-right {
  float: right;
}
.myBilling-card .float-right-extra {
  min-width: 100px;
  margin-right: 20px;
}

.myBilling-card hr {
  margin: 0 0 10px 0;
}

// Payments
.make-payment-head .heading-text {
  font-size: 25px;
}
.make-payment-head .heading-img {
  font-size: 10px;
}
.pay-area {
  clear: both;
}
.pay-area .row {
  padding: 10px;
}
.pay-area .row .form-group {
  width: 50%;
}
.pay-area .payment-field-row .form-group {
  // width:50%
}

.detail-section-heading {
  margin-left: 10px;
  margin-top: 10px;
}

.sidebar .nav-dropdown.open > .nav-dropdown-items > .nav-item > .active {
  background: grey;
}

.changeRequestCardHeader {
  padding: 7px !important;
}

.bookingDataTable .react-bs-table table td {
  white-space: normal !important;
}

//  Hide checkbox for light weight bookings
// disabled logic written on pending confirmation table
.pendingConfirmationTable input[type="checkbox"]:disabled {
  display: none;
}

// To match style in service detail and customer detail components
// .service-details tr td:first-child{
//   width: "260px"
// }

// .customer-details tr td:first-child{
//   width: "260px"
// }

// Full Calender component css over-write
.customer-table-header-width {
  width: 45%;
}

.fc-event-container {
  padding: 1px !important;
}

.fc-title {
  cursor: pointer;
  // padding-top: 20px;
}
//---------------------------------------
.pulsate::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: pulse 1s ease infinite;
  border-radius: 50%;
  border: 4px double lighten(red, 20%);
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.3);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
.sendbird-chat-header__right,
.sendbird-channel-preview__content__upper__header__total-members,
.sendbird-channel-header__right-icon {
  display: none !important;
}
.sendbird-channel-preview__content__upper__header__channel-name {
  font-weight: normal !important;
}
.sendbird-channel-preview__content__lower__unread-message-count
  .sendbird-badge
  .sendbird-badge__text
  span {
  display: block !important;
  margin-top: 4px;
}

.sendbird-chat-header__right,
.sendbird-channel-preview__content__upper__header__total-members,
.sendbird-channel-header__right-icon {
  display: none !important;
}
.sendbird-channel-preview__content__upper__header__channel-name {
  font-weight: normal !important;
}
.sendbird-channel-preview__content__lower__unread-message-count
  .sendbird-badge
  .sendbird-badge__text
  span {
  display: block !important;
  margin-top: 4px;
}
.desktop-view .sendbird-channel-header {
  width: 100% !important;
}
.desktop-view .sendbird-app__wrap .sendbird-app__channellist-wrap {
  max-width: 400px !important;
}
.desktop-view .sendbird-channel-list {
  width: 400px !important;
}
.desktop-view .sendbird-channel-preview {
  width: 100% !important;
}
.desktop-view
  .sendbird-channel-preview
  .sendbird-channel-preview__content
  .sendbird-channel-preview__content__upper {
  width: 100% !important;
}
.desktop-view
  .sendbird-channel-preview
  .sendbird-channel-preview__content
  .sendbird-channel-preview__content__upper__header__channel-name {
  max-width: 300px !important;
}
.sendbird-channel-preview__action {
  display: none !important;
}
@media only screen and (max-width: 600px) {
  .chat-screen {
    overflow-x: scroll;
  }
  .channel-info {
    width: 97%;
  }
}

.form-select {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.btn-close::after {
  content: "x";
}
button.btn-close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.modal-header .btn-close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
.card-title {
  font-weight: 500;
  font-size: 1.09375rem;
}

.search {
  position: absolute;
  top: 0;
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -2px;
}
/* Chrome, Safari, Edge, Opera */
input[name="phoneNumber"]::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[name="phoneNumber"] {
  -moz-appearance: textfield;
}
.is-r {
  position: relative;
}
.is-a {
  position: absolute;
}
.right {
  right: 0;
}
.ticket-visibility {
  top: 3px;
}

.badge-sm-warning {
  background-color: #ed6329 !important;
  color: #fff;
}

.chat-tab.nav-link:hover {
  background-color: var(--sendbird-dark-primary-300);
  color: #fff !important;
}
.partner-chat-tab.nav-link:hover {
  background-color: #ed5c2b;
  color: #fff !important;
}

.tickets-tab.nav-link:hover {
  background-color: var(--warning);
  color: #fff !important;
}
.line-through {
  text-decoration: line-through;
}
